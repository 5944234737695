body,
#firstRow,
#root {
  width: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, #8da9fc 0%, #b48dd5 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 800px) {
  #worldcontainer {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
  .removeOnMobile {
    display: none;
  }
}
